.main {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: var(--background);
  color: white;
  font-size: calc(10px + 2vmin);
  text-align: center;
  overflow: hidden;
}

.content {
  width: 100vw;
  display: flex;
}
