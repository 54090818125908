.circleList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* width: 50vw; */
  width: inherit;
  font-size: calc(10px + 2vmin);
  text-align: center;
}
