@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Nunito:ital,wght@1,200&display=swap");

.mainCircle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15vw;
  height: 15vw;
  background-color: var(--primary);
  border-radius: 50%;
  opacity: 0.95;
}

.title {
  position: absolute;
  font-family: "Merriweather", serif;
  color: var(--text);
}
