@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Nunito:ital,wght@1,200&display=swap");

.appCircle {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--secondary);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}

.appText {
  font-family: "Merriweather", serif;
  font-size: 14px;
  color: var(--text);
  margin: 5px;
}
