@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Nunito:ital,wght@1,200&display=swap");

.title {
  color: var(--accent);
  font-size: 26px;
  padding: 0;
  margin: 0;
}

.description {
  color: var(--text);
  padding: 0;
}

.content {
  font-family: "Merriweather", serif;
  font-size: 18px;
  line-height: 1.75;
  text-align: left;
  max-width: 50vw;
  min-width: 50vw;
  display: flex;
  box-sizing: border-box;
  padding: 70px;
  justify-content: center;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  z-index: 10;
}

.content span {
  color: var(--accent);
}
