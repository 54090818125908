@import url("https://fonts.cdnfonts.com/css/rubik-glitch");

.title {
  font-family: "Rubik Glitch", sans-serif;
  position: absolute;
  color: var(--text-light);
  font-size: 264px;
  margin: 0;
  padding: 0;
  user-select: none;
}

.title:first-of-type {
  left: 200px;
  opacity: 0.5;
}

.title:nth-of-type(2) {
  left: -50px;
  top: 500px;
  font-size: 150px;
  opacity: 0.5;
}

.title:nth-of-type(3) {
  left: -50px;
  top: 700px;
  font-size: 150px;
  opacity: 0.5;
}
